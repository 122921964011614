import React from 'react'
import styled from 'styled-components'

import { CompanyInfo } from '../constants'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0 auto;

  .conditions-wrapper {
    padding: 0 3rem 2rem 3rem;
  }

  p {
    text-align: left;
  }
`

const ConditionsPage = () => (
  <Wrapper>
    <div className="conditions-wrapper">
      <h1>Правила проживания</h1>
      <p>1. Общие положения:</p>
      <ul>
        <li>
          Настоящие Правила распространяются на всех отдыхающих, находящихся на
          территории базы отдыха «Золотая Звезда» (далее – База отдыха).
        </li>
        <li>
          База отдыха предназначена для отдыха и временного проживания граждан.
        </li>
        <li>
          Прием и регистрация отдыхающих производится строго по паспорту (или
          водительскому удостоверению) администратором или лицом его
          заменяющим,с предъявлением ваучера.
        </li>
        <li>
          При заезде на Базу отдыха всем гостям бесконтактно измеряется
          температура тела. В случае обнаружения повышенной температуры тела
          (более 36,9 градусов) в размещении на территории Базы отдыха будет
          отказано, при этом все уплаченные денежные средства будут возвращены в
          сроки, предусмотренные действующим законодательством РФ.
        </li>
        <li>
          После регистрации администратор выдает отдыхающим ключи от номера, в
          случае необходимости проводит обзорный инструктаж.
        </li>
        <li>
          Размещение отдыхающих на Базе отдыха производится администрацией в
          соответствии с местами, указанными в ваучере или договоре.
        </li>
        <li>Время заезда – 17.00. Время выезда – 14.00.</li>
        <li>
          При длительном проживании на Базе отдыха, более 7 дней, уборка номера
          производится 1 раз в неделю. Внеплановая уборка осуществляется за
          дополнительную плату.
        </li>
      </ul>
      <p>
        2. Дополнительные услуги, предоставляемые Базой отдыха, оплачиваются
        отдельно согласно действующему прейскуранту, путем единовременного
        внесения наличных денежных средств в кассу.
      </p>
      <p>3. Все отдыхающие имеют одинаковые права и должны:</p>
      <ul>
        <li>
          соблюдать установленный настоящими Правилами порядок пребывания на
          Базе отдыха;
        </li>
        <li>соблюдать правила пожарной безопасности;</li>
        <li>
          воздерживаться в местах массового отдыха гостей от чрезмерного
          употребления алкоголя и нецензурных выражений, соблюдать
          морально-этические нормы;
        </li>
        <li>уважать право других гостей на отдых;</li>
        <li>
          не оскорблять действиями и словами других гостей и обслуживающий
          персонал;
        </li>
        <li>
          беречь имущество Базы отдыха, переданное во временное пользование, в
          случае нанесения ущерба постройкам, порчи или утраты имущества Базы
          отдыха по вине отдыхающего и/или лиц, находящихся с ним на отдыхе,
          отдыхающий обязан компенсировать нанесенный ущерб в соответствии с
          рыночными ценами, действующими на момент компенсации ущерба;
        </li>
        <li>
          соблюдать тишину в комнатах и на территории Базы отдыха с 23.00 часов
          до 09.00 часов;
        </li>
        <li>
          сообщать администратору о возникновении на территории каких-либо
          нарушений;
        </li>
        <li>парковать автомобиль в специально отведенном месте;</li>
        <li>закрывать дверь на ключ, покидая номер.</li>
      </ul>
      <p>4. На территории Базы отдыха ЗАПРЕЩЕНО:</p>
      <ul>
        <li>
          разводить огонь вне специально оборудованных мест (ШТРАФ 5000 рублей);
        </li>
        <li>
          оставлять детей без присмотра на всей территории Базы отдыха, а также
          возле водоема;
        </li>
        <li>употреблять наркотические средства;</li>
        <li>курить в помещениях базы отдыха (ШТРАФ 3000 рублей);</li>
        <li>
          находиться на территории базы отдыха с заряженным и собранным оружием;
        </li>
        <li>
          стрелять из огнестрельного, охотничьего и пневматического оружия;
        </li>
        <li>мыть автотранспорт на территории Базы отдыха;</li>
        <li>заезжать на газоны;</li>
        <li>
          переставлять мебель в номерах без разрешения Администрации базы;
        </li>
        <li>
          выносить из номеров предметы мебели, постельное белье, полотенца;
        </li>
        <li>оставлять включенными электроприборы, выходя из номера;</li>
        <li>разбрасывать мусор, отходы, окурки на территории Базы отдыха;</li>
        <li>
          устанавливать палатки, тенты, навесы и другие конструкции на
          территории Базы отдыха без согласования с Администрацией базы;
        </li>
        <li>пребывание туристов, не включенных в путевку после 23.00;</li>
        <li>
          несанкционированное использование фейерверков (ШРАФ 10 000 рублей);
        </li>
        <li>размещение с животными любых пород.</li>
      </ul>
      <p>5. По окончании действия договора отдыхающий ДОЛЖЕН:</p>
      <ul>
        <li>
          не позднее, чем за 15 минут до установленного времени выезда
          отдыхающий должен обратиться в администрацию Базы отдыха лично и сдать
          помещение;
        </li>
        <li>
          передать администратору в целости и сохранности и в надлежащем порядке
          вверенное ему имущество Базы отдыха;
        </li>
        <li>
          оплатить штраф, если были нарушены правила проживания или нанесен вред
          имуществу Базы отдыха (любой нанесенный материальный ущерб имуществу
          Базы отдыха оплачивается отдыхающими в полном объеме, размер (сумма)
          ущерба рассчитывается администратором по рыночной стоимости имущества;
        </li>
        <li>
          покинуть номер, баню, место отдыха и территорию Базы отдыха в час,
          указанный в пункте 1.7., если договор не был продлен заранее (за час
          до окончания).
        </li>
      </ul>
      <p>6. Задержка заезда и выезда. </p>
      <ul>
        <li>
          Продление пребывания более срока, указанного в договоре, возможно
          только в случае отсутствия брони на данный номер для других
          отдыхающих, за дополнительную оплату по прейскуранту.
        </li>
        <li>
          В случае задержки выезда после расчетного часа не более 6 часов –
          плата взимается за каждый час, от 6 до 12 часов – за половину суток,
          свыше 12 часов – за полные сутки.
        </li>
        <li>
          В случае выезда отдыхающего до окончания срока пребывания уплаченная
          им денежная сумма не пересчитывается и возврату не подлежит. Если
          досрочный выезд отдыхающего связан с некачественным оказанием услуг
          базой отдыха, вопрос о возврате уплаченной денежной суммы решается
          администрацией базы отдыха на основании направленной в ее адрес
          претензии.
        </li>
        <li>
          В случае неиспользования отдыхающим оплаченного жилья, стоимость
          забронированной услуги не возвращается.
        </li>
        <li>
          При опоздании (не заезде) взимается плата за фактический простой
          номера из расчета 100% стоимости в сутки.
        </li>
        <li>
        В случае, когда отдыхающий, заключивший договор на размещение и проживание, отказывается от услуг: 
        за 3 суток включительно и более - предоплата возвращается в полном объёме;
        от 2 до 1 суток - взимается стоимость первых суток проживания;
        менее 1 суток - предоплата не возвращается.
        </li>
      </ul>
      <h2>ВНИМАНИЕ:</h2>
      <ul>
        <li>
          Администрация базы отдыха оставляет за собой право отказать в
          предоставлении услуг отдыхающим, нарушающим настоящие Правила
          пребывания (проживания) на Базе отдыха без возмещения стоимости услуг.
        </li>
        <li>
          Администрация базы отдыха оставляет за собой право посещения номера,
          без согласования с отдыхающим в случае задымления, пожара, затопления,
          а также в случае нарушения отдыхающим (гостем) настоящего Порядка,
          общественного порядка, порядка пользования бытовыми приборами.
        </li>
        <li>
          Администрация Базы отдыха не несет ответственности за утерю багажа и
          личных вещей отдыхающего, за любые медицинские расходы, возникшие в
          результате несчастных и других страховых случаев, за случаи краж
          личного имущества в месте пребывания отдыхающего.
        </li>
        <li>
          Администрация Базы отдыха не несет ответственности за противоправные
          действия других отдыхающих и третьих лиц; за несчастные случаи и
          травмы, произошедшие по вине отдыхающего во время его пребывания на
          базе отдыха, с самим отдыхающим или с сопровождающими его лицами.
        </li>
      </ul>

      <p>
        Вопросы, не оговоренные в настоящих Правилах, согласовываются с
        администрацией.
      </p>
    </div>
  </Wrapper>
)

export default ConditionsPage
